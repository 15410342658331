:root {
  --spacePart: 15px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 15px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 35px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 35px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 20px;
  }
}
:root {
  --spaceTotal: 50px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 50px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #204e7b;
  line-height: 1.5;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #1b4167;
}
.button:active {
  background-color: #10283e;
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #204e7b;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: bold;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
}
.unit caption {
  display: none;
}
.flag {
  background: #204e7b;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit .head,
.unit .body,
.unit .foot {
  float: left;
  width: 100%;
}
.unit.fold div.less,
.unit.fold div.more {
  float: left;
  width: 100%;
}
.unit.fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 7px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #000;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 30px;
  position: relative;
}
.list--bullet li:not(:last-child) {
  margin-bottom: 5px;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 30px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 500px !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
}
@media (max-width: 1023px) {
  #slides {
    height: 300px !important;
  }
}
@media (max-width: 767px) {
  #slides {
    height: 200px !important;
  }
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
  position: relative;
}
.cb-slides .cb-image-container:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.5) 100%);
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #204e7b;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #204e7b;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.42857143;
  color: #000;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #204e7b;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #204e7b;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: #204e7b;
  line-height: 1.5;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #fff;
  background-color: #1b4167;
}
#disp .foot input:active,
#disp .foot a:active {
  background-color: #10283e;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Roboto', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #F0F0F0;
  min-height: 40px;
  padding: 5px 10px;
  -webkit-appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 30px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 5px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  line-height: 1.5;
  background: #F0F0F0;
  height: 40px;
  border: 1px solid #000;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  background-color: #204e7b;
  line-height: 1.5;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  color: #fff;
  background-color: #1b4167;
}
.unit.form input.submit:active {
  background-color: #10283e;
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: #204e7b;
  line-height: 1.5;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #fff;
  background-color: #1b4167;
}
.two-step-verification-container a:active {
  background-color: #10283e;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table.table--plain {
  margin-top: -5px;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.section--one .area .edge .table tr {
  border: none;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.section--one .area .edge .table th {
  padding: 5px 10px;
}
.table th.init {
  padding-left: 0 !important;
}
.table th.exit {
  padding-right: 0 !important;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: bold;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.section--one .area .edge .table td {
  padding: 5px 10px;
}
.table td.init {
  padding-left: 0 !important;
}
.table td.exit {
  padding-right: 0 !important;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
}
@media (max-width: 767px) {
  .part--table {
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .part--table::-webkit-scrollbar {
    display: none;
  }
  .part--table .table {
    white-space: nowrap;
    width: auto;
    min-width: 100%;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
  height: 40px;
}
@media (max-width: 1239px) {
  #home {
    height: 30px;
  }
}
@media (max-width: 767px) {
  #home {
    height: 22px;
  }
}
.logo {
  float: left;
  width: auto;
  height: 56px;
  margin-top: -16px;
}
@media (max-width: 1239px) {
  .logo {
    margin-top: -10px;
    height: 40px;
  }
}
@media (max-width: 767px) {
  .logo {
    height: 30px;
    margin-top: -8px;
  }
}
#head {
  float: left;
  width: 100%;
  margin-top: 14px;
}
@media (max-width: 1023px) {
  #head {
    margin-top: 10px;
  }
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  line-height: 1.5;
  color: #000;
  overflow: hidden;
  hyphens: none;
  font-weight: 300;
  box-sizing: border-box;
  padding-top: 204px;
  opacity: 0;
  transition: all 0.6s;
  -webkit-font-smoothing: antialiased;
}
.show-content .wrapper {
  opacity: 1;
}
@media (max-width: 1023px) {
  .wrapper {
    padding-top: 104px;
  }
}
@media (max-width: 767px) {
  .wrapper {
    padding-top: 74px;
  }
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 88%;
  width: 1170px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section--three .desk {
  width: 770px;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  background-color: #fff;
  border-bottom: 4px solid #8ca0c8;
}
.section--footer {
  background: radial-gradient(ellipse at top, #7593b0 0, #204e7b 80%);
  box-shadow: 0 18px 21px -4px #000000;
  color: #fff;
  margin-top: var(--spaceTotal);
}
.section--footer a {
  color: #fff;
}
.section--unitFader {
  display: none;
}
.cb-layout1 .section--unitFader.section--areaTwenty {
  display: block;
}
#view.areaTwenty--empty.cb-layout1 .section--unitFader.section--areaTwenty {
  display: none;
}
.section--two {
  color: #204e7b;
}
.headercontent {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 200px;
  transition: all 0.4s;
}
.cb-scroll-triggered--active .headercontent {
  min-height: 120px;
}
@media (max-width: 1023px) {
  .headercontent {
    min-height: 100px;
  }
  .cb-scroll-triggered--active .headercontent {
    min-height: 100px;
  }
}
@media (max-width: 767px) {
  .headercontent {
    min-height: 70px;
  }
  .cb-scroll-triggered--active .headercontent {
    min-height: 70px;
  }
}
.navigation {
  float: left;
  display: flex;
  align-items: center;
}
@media (max-width: 1023px) {
  .navigation {
    display: none;
  }
}
.moodcontent {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-30%) translateX(-50%);
  z-index: 2;
  width: 500px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 50px;
  border-top: 6px solid #8ca0c8;
  text-align: center;
  opacity: 0;
  border-radius: 0 0 4px 4px;
  transition: all 0.6s;
}
.moodcontent:after {
  content: '';
  position: absolute;
  left: 0;
  top: -6px;
  width: 33.33333333%;
  height: 6px;
  background-color: #204e7b;
  z-index: 2;
}
.cb-layout3 .moodcontent {
  display: none;
}
.show-content .moodcontent {
  opacity: 1;
  transform: translateY(-50%) translateX(-50%);
}
@media (max-width: 1023px) {
  .moodcontent {
    width: 430px;
    padding: 36px;
  }
}
@media (max-width: 767px) {
  .moodcontent {
    position: relative;
    left: 0;
    float: left;
    width: 88%;
    padding: 24px;
    margin: -60px 6% 0;
    box-shadow: 0 0 10px rgba(120, 120, 120, 0.4);
    transform: translateY(100px) translateX(0);
  }
  .show-content .moodcontent {
    transform: translateY(0) translateX(0);
  }
}
.moodcontent__suptitle {
  font-size: 18px;
  line-height: 1.33333333;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  color: #204e7b;
}
.stoerer {
  position: absolute;
  right: 200px;
  bottom: -90px;
  z-index: 3;
  width: 180px;
  opacity: 0;
  transform: translateX(60px);
  transition: all 0.6s 0.2s;
}
.show-content .stoerer {
  opacity: 1;
  transform: translateX(0);
}
.cb-layout3 .stoerer,
.cb-layout4 .stoerer {
  display: none;
}
@media (max-width: 1719px) {
  .stoerer {
    right: 5%;
  }
}
@media (max-width: 1023px) {
  .stoerer {
    width: 148px;
    bottom: -74px;
  }
}
@media (max-width: 767px) {
  .stoerer {
    width: 114px;
    bottom: initial;
    top: 60px;
    right: 3%;
  }
}
.stoerer__text {
  float: left;
  width: 100%;
  border-radius: 10000px;
  height: 180px;
  background-color: #204e7b;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1.33333333;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.stoerer__text strong {
  font-weight: 400;
}
.stoerer__text:hover,
.stoerer__text:focus {
  background-color: #8ca0c8;
}
@media (max-width: 1023px) {
  .stoerer__text {
    height: 148px;
    font-size: 16px;
    line-height: 1.25;
  }
}
@media (max-width: 767px) {
  .stoerer__text {
    height: 114px;
    font-size: 12px;
    line-height: 1.33333333;
  }
}
.stoerer__text a {
  float: left;
  width: 100%;
  height: 180px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  color: #fff;
  padding: 20px 20px 80px;
  position: relative;
}
.stoerer__text a:after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
  width: 28px;
  height: 18px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-white.svg);
  transition: all 0.3s;
}
.stoerer__text a:hover,
.stoerer__text a:focus {
  color: #fff;
}
.stoerer__text a:hover:after,
.stoerer__text a:focus:after {
  margin-left: 6px;
}
@media (max-width: 1023px) {
  .stoerer__text a {
    padding: 20px 20px 64px;
    height: 148px;
  }
  .stoerer__text a:after {
    bottom: 34px;
  }
}
@media (max-width: 767px) {
  .stoerer__text a {
    padding: 20px 10px 50px;
    height: 114px;
  }
  .stoerer__text a:after {
    content: '';
    bottom: 26px;
  }
}
.downlink {
  position: absolute;
  left: 50%;
  bottom: -16px;
  z-index: 2;
  transform: translateX(-50%);
  width: 32px;
  height: 32px;
  border: 2px solid #fff;
  border-radius: 10000px;
  background-color: #fff;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-circle-down-blue.svg);
  cursor: pointer;
}
@media (max-width: 767px) {
  .downlink {
    display: none;
  }
}
.content {
  float: left;
  width: 100%;
}
.section--contactLink .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subcontent {
  float: left;
  width: 100%;
}
.toplinkWrapper {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.toplink {
  float: left;
  width: 32px;
  height: 32px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  cursor: pointer;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-circle-top-blue.svg);
}
.footcontent {
  float: left;
  width: 100%;
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 2);
  font-family: 'Roboto Condensed', sans-serif;
}
.footunit {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.footunit--2 {
  border-top: 1px solid #fff;
  align-items: center;
  padding-top: 20px;
  margin-top: 36px;
}
.footpart {
  float: left;
}
.vcard {
  float: left;
  display: flex;
  font-size: 20px;
  line-height: 1.6;
}
@media (max-width: 1023px) {
  .vcard {
    font-size: 18px;
    line-height: 1.44444444;
  }
}
.vcardpart {
  float: left;
  margin-left: 60px;
}
.vcardpart:first-child {
  margin-left: 0;
}
@media (max-width: 1023px) {
  .vcardpart {
    margin-left: 40px;
  }
}
.vcard .vcardemail {
  display: inline-block;
  padding-left: 60px;
  background-size: 44px 20px;
  background-position: 0 6px;
  background-repeat: no-repeat;
  background-image: url(/images/icon-mail-white.svg);
}
.vcard .vcardemail:hover,
.vcard .vcardemail:focus {
  background-image: url(/images/icon-mail-lightblue.svg);
}
@media (max-width: 1023px) {
  .vcard .vcardemail {
    background-position: 0 4px;
    padding-left: 54px;
  }
}
.vcard .adr {
  display: inline-block;
  padding-left: 42px;
  background-size: 26px 26px;
  background-position: 0 3px;
  background-repeat: no-repeat;
  background-image: url(/images/icon-map-white.svg);
}
.vcard .adr:hover,
.vcard .adr:focus {
  background-image: url(/images/icon-map-lightblue.svg);
}
@media (max-width: 1023px) {
  .vcard .adr {
    background-position: 0 0;
    padding-left: 38px;
  }
}
.vcard .tel {
  display: inline-block;
  padding-left: 42px;
}
@media (max-width: 1023px) {
  .vcard .tel {
    padding-left: 38px;
  }
}
#social {
  float: left;
}
#social .meta {
  float: left;
  width: 24px;
  height: 24px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/social-instagram-white.svg);
  margin-right: 30px;
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta:hover,
#social .meta:focus {
  background-image: url(/images/social-instagram-lightblue.svg);
}
#social .meta.service_facebook {
  width: 13px;
  background-image: url(/images/social-facebook-white.svg);
}
#social .meta.service_facebook:hover,
#social .meta.service_facebook:focus {
  background-image: url(/images/social-facebook-lightblue.svg);
}
#social .meta.service_linkedin {
  width: 25px;
  background-image: url(/images/social-linkedin-white.svg);
}
#social .meta.service_linkedin:hover,
#social .meta.service_linkedin:focus {
  background-image: url(/images/social-linkedin-lightblue.svg);
}
@media (max-width: 1023px) {
  #social .meta {
    margin-right: 20px;
  }
}
#services {
  float: left;
  font-size: 14px;
  line-height: 1.42857143;
}
#services .meta {
  float: left;
  margin-right: 10px;
}
#services .meta:last-child {
  margin-right: 0;
}
.messerliGroup {
  float: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.messerliGroup__text {
  float: left;
  font-size: 14px;
  line-height: 1.42857143;
}
.messerliGroup__link {
  float: left;
  width: 67px;
  height: 20px;
  margin-left: 10px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/logo-messerligroup.png);
}
.pager {
  float: left;
  width: 100%;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  border-top: 1px solid #204e7b;
  padding-top: 14px;
  display: none;
}
.cb-layout3 .pager {
  display: block;
}
.pager__part {
  float: left;
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.pager__part--2 {
  align-items: center;
}
.pager__part--3 {
  align-items: flex-end;
}
.pager__link {
  float: left;
  cursor: pointer;
  font-size: 24px;
  line-height: 1.16666667;
  font-family: 'Roboto Condensed', sans-serif;
  color: #204e7b;
}
.pager__link:hover,
.pager__link:focus {
  color: #8ca0c8;
}
@media (max-width: 1023px) {
  .pager__link {
    font-size: 20px;
    line-height: 1.4;
  }
}
@media (max-width: 767px) {
  .pager__link {
    font-size: 14px;
    line-height: 1.57142857;
  }
}
.pager__link--prev,
.pager__link--next {
  background-size: 19px 12px;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .pager__link--prev,
  .pager__link--next {
    background-size: auto 8px;
  }
}
.pager__link--prev {
  padding-left: 30px;
  background-position: 0 50%;
  background-image: url(/images/arrow-left-blue.svg);
}
.pager__link--prev:hover,
.pager__link--prev:focus {
  background-image: url(/images/arrow-left-lightblue.svg);
}
@media (max-width: 767px) {
  .pager__link--prev {
    padding-left: 20px;
  }
}
.pager__link--next {
  padding-right: 30px;
  background-position: 100% 50%;
  background-image: url(/images/arrow-right-blue.svg);
}
.pager__link--next:hover,
.pager__link--next:focus {
  background-image: url(/images/arrow-right-lightblue.svg);
}
@media (max-width: 767px) {
  .pager__link--next {
    padding-right: 20px;
  }
}
.cbdModule--contactLink {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  width: auto;
}
.cbdModule--contactLink .open {
  float: left;
  background-color: #204e7b;
  line-height: 1.5;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
}
.cbdModule--contactLink .open:hover,
.cbdModule--contactLink .open:focus {
  color: #fff;
  background-color: #1b4167;
}
.cbdModule--contactLink .open:active {
  background-color: #10283e;
}
a {
  color: #204e7b;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #8ca0c8;
}
h1 {
  font-size: 36px;
  line-height: 1.11111111;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  color: #204e7b;
}
h1 strong {
  font-weight: 400;
}
h2 {
  font-size: 20px;
  line-height: 1.2;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3px;
  border-bottom: 1px solid #204e7b;
  padding-bottom: 15px;
  color: #204e7b;
}
h3 {
  font-size: 30px;
  line-height: 1.26666667;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  color: #204e7b;
}
h3 strong {
  font-weight: 400;
}
h4 {
  font-size: 24px;
  line-height: 1.25;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  color: #204e7b;
}
h4 strong {
  font-weight: 400;
}
.section--one .area .seam.wide h4 {
  padding-right: 60px;
  background-size: 28px 18px;
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-blue.svg);
}
h5 {
  border-bottom: 1px solid #204e7b;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  color: #204e7b;
  padding-bottom: 5px;
}
h6 {
  font-size: 30px;
  line-height: 1.26666667;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  color: #204e7b;
  text-align: center;
}
h6 strong {
  font-weight: 400;
}
.pale {
  font-size: 18px;
  line-height: 1.33333333;
  font-family: 'Roboto Condensed', sans-serif;
  letter-spacing: 1px;
}
.pale strong {
  font-weight: 400;
}
.loud {
  font-size: 18px;
  line-height: 1.33333333;
  color: #204e7b;
}
.loud strong {
  display: inline-block;
  padding-bottom: 4px;
  font-size: 36px;
  line-height: 1.11111111;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}
.footQuicklink {
  border-top: 1px solid #204e7b;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  padding-top: 14px;
  font-size: 24px;
  line-height: 1.16666667;
  font-family: 'Roboto Condensed', sans-serif;
  color: #204e7b;
}
.footQuicklink a {
  float: left;
  padding-right: 30px;
  background-position: 100% 50%;
  background-size: 19px 12px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-blue.svg);
  transition: all 0.3s;
}
.footQuicklink a:hover,
.footQuicklink a:focus {
  color: #8ca0c8;
  background-image: url(/images/arrow-right-lightblue.svg);
}
@media (max-width: 1023px) {
  .footQuicklink {
    font-size: 20px;
    line-height: 1.4;
  }
}
@media (max-width: 767px) {
  .footQuicklink {
    font-size: 16px;
    line-height: 1.375;
  }
}
.area {
  opacity: 0;
  transform: translateY(60px);
  transition: all 0.6s 0.2s;
}
.show-content .area {
  opacity: 1;
  transform: translateY(0);
}
.section--one .area {
  display: flex;
  flex-wrap: wrap;
}
.cb-layout1 .section--one .area.main {
  margin-top: var(--spaceTotal);
}
.section--two .subcontent--1 .area {
  margin-top: var(--spaceTotal);
}
.section--two .subcontent--2 .area {
  margin-top: var(--spaceTotal);
}
@media (max-width: 767px) {
  .section--two .subcontent--2 .area {
    margin-top: 0;
  }
}
.section--three .area {
  margin-top: var(--spaceTotal);
}
.section--one .area .pure.wide .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.section--one .area .seam {
  background-color: #e4ecf5;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  border-radius: 4px;
  overflow: hidden;
  padding: 25px 30px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 1.33333333;
}
.section--one .area .seam.ns-unitFilter__unit {
  margin-top: var(--spaceTotal) !important;
}
.section--one .area .seam + .seam + .seam {
  margin-top: calc((var(--spaceTotal) - 30px) * -1);
}
.section--one .area .seam .part {
  margin-top: 5px;
  margin-bottom: 5px;
}
.section--one .area .seam .pict.tall:first-child {
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-top: -25px;
  margin-bottom: 25px;
}
.section--one .area .seam .pict.tall:first-child .cb-image-caption {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 2;
  width: 342px;
  max-width: 90%;
  box-sizing: border-box;
  padding: 5px 10px;
  text-align: center;
  background-color: #204e7b;
  color: #fff;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 1.42857143;
}
@media (max-width: 1023px) {
  .section--one .area .seam {
    padding: 15px 20px;
  }
  .section--one .area .seam .pict.tall:first-child {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: -15px;
    margin-bottom: 15px;
  }
  .section--one .area .seam .pict.tall:first-child .cb-image-caption {
    width: 280px;
  }
}
@media (max-width: 767px) {
  .section--one .area .seam + .seam {
    margin-top: calc((var(--spaceTotal) - 30px) * -1);
  }
}
#view .section--one .area .seam.wide {
  transition: all 0.4s;
}
#view .section--one .area .seam.wide div.link {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
}
#view .section--one .area .seam.wide div.link a.open {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#view .section--one .area .seam.wide div.pict .cb-image-container {
  overflow: hidden;
}
#view .section--one .area .seam.wide div.pict .cb-image-container img {
  transition: all 0.4s;
}
#view .section--one .area .seam.wide:hover {
  box-shadow: 0 15px 20px -10px #ccc;
}
#view .section--one .area .seam.wide:hover div.pict .cb-image-container img {
  transform: scale(1.05);
}
.section--one .area .seam.slim div.link + div.link {
  margin-top: 0;
}
.section--one .area .flat {
  background-color: #e4ecf5;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  border-radius: 4px;
  overflow: hidden;
  padding: 20px 30px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 1.44444444;
}
.section--one .area .flat + .flat + .flat + .flat {
  margin-top: calc((var(--spaceTotal) - 30px) * -1);
}
.section--one .area .flat .part {
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 1023px) {
  .section--one .area .flat {
    padding: 10px 20px;
  }
  .section--one .area .flat + .flat + .flat {
    margin-top: calc((var(--spaceTotal) - 30px) * -1);
  }
}
@media (max-width: 767px) {
  .section--one .area .flat + .flat {
    margin-top: calc((var(--spaceTotal) - 30px) * -1);
  }
}
.section--one .area .edge {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  border-radius: 4px;
  overflow: hidden;
  padding: 15px 20px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.28571429;
  font-family: 'Roboto Condensed', sans-serif;
}
body.de .section--one .area .edge {
  background-color: #f1f1f1;
}
.section--one .area .edge strong {
  font-weight: 400;
}
.section--one .area .edge + .edge + .edge + .edge + .edge {
  margin-top: calc((var(--spaceTotal) - 30px) * -1);
}
.section--one .area .edge .part {
  margin-top: 5px;
  margin-bottom: 5px;
}
.section--one .area .edge .pict.tall:first-child {
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-top: -15px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 2px solid #666;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
}
.section--one .area .edge .pict.tall:first-child .cb-image-figure {
  height: 136px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section--one .area .edge .pict.tall:first-child .cb-image-container {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
.section--one .area .edge .pict.tall:first-child .cb-image-container img {
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain;
  object-position: center;
}
@media (max-width: 1023px) {
  .section--one .area .edge {
    padding: 9px 14px;
  }
  .section--one .area .edge + .edge + .edge + .edge {
    margin-top: calc((var(--spaceTotal) - 30px) * -1);
  }
  .section--one .area .edge .pict.tall:first-child {
    width: calc(100% + 28px);
    margin-left: -14px;
    margin-top: -9px;
    margin-bottom: 9px;
  }
}
@media (max-width: 767px) {
  .section--one .area .edge + .edge + .edge {
    margin-top: calc((var(--spaceTotal) - 30px) * -1);
  }
  .section--one .area .edge .pict.tall:first-child .cb-image-figure {
    height: 100px;
  }
}
@media (max-width: 1023px) {
  .navi {
    display: none;
  }
}
.togglenavigation {
  float: left;
  position: relative;
  width: 30px;
  height: 16px;
  cursor: pointer;
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 2px;
  background-color: #204e7b;
  border-radius: 2px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), opacity 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateX(-20px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateX(20px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
  height: 100%;
  width: 100%;
  max-width: 320px;
  background: linear-gradient(to bottom, #7593b0 0, #204e7b 100%);
  overflow-y: scroll;
  transform: translateX(100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateX(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  transform: translateX(100px);
  transition: all 1.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transform: translateX(0);
  transition: all 0.8s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation div.navi {
  float: left;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  line-height: 1.44444444;
}
.mobile-navigation div.sub1 {
  box-sizing: border-box;
  padding: 0 6vw;
  margin: 50px 0 80px;
  color: #fff;
  font-size: 18px;
  line-height: 1.44444444;
}
.mobile-navigation div.sub1 > .item:after {
  content: '|';
  float: left;
  margin: 0 8px;
}
.mobile-navigation div.sub1 > .item.exit:after {
  display: none;
}
.mobile-navigation div.sub1 > .item > .menu {
  float: left;
  text-transform: uppercase;
}
.mobile-navigation div.sub1 > .item > .menu.path {
  font-weight: bold;
}
.mobile-navigation div.sub2 {
  box-sizing: border-box;
  padding: 0 6vw;
  margin-top: 144px;
}
.mobile-navigation div.sub2 > .item {
  width: 100%;
}
.mobile-navigation div.sub2 > .item.path {
  margin-top: 7px;
  margin-bottom: 7px;
}
.mobile-navigation div.sub2 > .item.path.init {
  margin-top: 0;
}
.mobile-navigation div.sub2 > .item.path.exit {
  margin-bottom: 0;
}
.mobile-navigation div.sub2 > .item > .menu {
  float: left;
  max-width: 100%;
  box-sizing: border-box;
  padding: 7px 0;
  border: 1px solid transparent;
}
.mobile-navigation div.sub2 > .item > .menu.path {
  border-color: #fff;
  padding: 7px 12px;
}
@media (max-width: 767px) {
  .mobile-navigation div.sub2 {
    margin-top: 104px;
  }
}
@media (max-width: 767px) {
  .pager__part--1 {
    width: 40%;
  }
  .pager__part--2 {
    width: 20%;
  }
  .pager__part--3 {
    width: 40%;
  }
}
@media (max-width: 767px) {
  .footcontent {
    margin: 40px 0;
  }
  .footunit {
    display: flex;
    flex-direction: column;
  }
  .footpart {
    width: 100%;
    margin-top: 40px;
  }
  .footpart:first-child {
    margin-top: 0;
  }
  .vcard {
    flex-direction: column;
  }
  .vcardpart {
    margin-left: 0;
    margin-top: 40px;
  }
  .vcardpart:first-child {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .wrapper {
    font-size: 16px;
    line-height: 1.625;
  }
  .moodcontent__suptitle {
    font-size: 16px;
    line-height: 1.625;
  }
  h1 {
    font-size: 28px;
    line-height: 1.14285714;
  }
  h2 {
    font-size: 16px;
    line-height: 1.625;
    padding-bottom: 10px;
  }
  h3 {
    font-size: 22px;
    line-height: 1.36363636;
  }
  h4 {
    font-size: 20px;
    line-height: 1.3;
  }
  .section--one .area .seam h4 {
    padding-right: 40px;
    background-size: 20px auto;
  }
  h5 {
    font-size: 15px;
    line-height: 1.2;
    padding-bottom: 3px;
  }
  h6 {
    font-size: 22px;
    line-height: 1.36363636;
  }
  .pale {
    font-size: 16px;
    line-height: 1.375;
  }
  .loud {
    font-size: 16px;
    line-height: 1.375;
  }
  .section--one .area .seam {
    font-size: 16px;
    line-height: 1.5;
  }
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim .part {
  width: 100%;
}
.section--one .area {
  width: 104%;
  margin-left: -2%;
}
.section--one .area .edge {
  width: 46%;
}
.unitFader {
  float: left;
  width: 100%;
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.unitFader__index {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.unitFader__indexItem {
  float: left;
  width: 18px;
  height: 18px;
  margin: 0 10px;
  box-sizing: border-box;
  border: 1px solid #204e7b;
  border-radius: 10000px;
  cursor: pointer;
  position: relative;
}
.unitFader__indexItem:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
  border-radius: 10000px;
  background-color: #204e7b;
  transform: translateY(-50%) translateX(-50%);
  transition: all 0.2s;
}
.unitFader__indexItem.is-active:after {
  width: 10px;
  height: 10px;
}
.unitFader__content {
  float: left;
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.unitFader .area {
  display: flex;
  align-items: center;
  max-width: 970px;
}
#edit .unitFader .area {
  display: block;
}
.unitFader .area .unit {
  flex-shrink: 0;
  margin-right: -100%;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;
  text-align: center;
  transform: scale(0.9);
  position: relative;
  z-index: 1;
  transition: all 0.4s;
}
.unitFader .area .unit .part {
  margin-top: 0;
  margin-bottom: 0;
}
.unitFader .area .unit .part + .part {
  margin-top: 40px;
}
.unitFader .area .unit.is-visible {
  opacity: 1;
  transform: scale(1);
  z-index: 2;
}
#edit .unitFader .area .unit {
  margin-right: 0;
  opacity: 1 !important;
  transform: scale(1) !important;
}
#edit .unitFader .area .unit + .unit {
  margin-top: calc(var(--spaceTotal) * 2);
}
.ns-unitFilter {
  float: left;
  width: 100%;
}
.ns-unitFilter__wrapper {
  float: left;
  width: 100%;
}
.ns-unitFilter__title {
  float: left;
  width: 100%;
}
.ns-unitFilter__toggle {
  display: none;
}
.ns-unitFilter__content {
  float: left;
  width: 100%;
}
.ns-unitFilter__list {
  float: left;
  width: 100%;
}
.ns-unitFilter__item {
  float: left;
  width: 100%;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 1.5;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  color: #204e7b;
  cursor: pointer;
  padding: 8px 25px;
  transition: all 0.2s;
  -webkit-font-smoothing: antialiased;
}
.ns-unitFilter__item:after {
  content: '';
  display: inline-block;
  margin-left: 10px;
  width: 19px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-blue.svg);
  transition: all 0.2s;
  transform: translateY(-1px);
}
.ns-unitFilter__item:hover,
.ns-unitFilter__item:focus {
  background-color: #e4ecf5;
  color: #204e7b;
}
.ns-unitFilter__item:hover:after,
.ns-unitFilter__item:focus:after {
  transform: translateY(-1px) translateX(6px);
}
.ns-unitFilter__item.is-active {
  background-color: #e4ecf5;
  color: #204e7b;
}
.ns-unitFilter__item.is-active:after {
  transform: translateY(-1px) translateX(0);
}
@media (max-width: 1239px) {
  .ns-unitFilter__item {
    padding: 8px 12px;
    font-size: 18px;
    line-height: 1.44444444;
  }
}
.ns-unitFilter__unit {
  display: none;
}
.ns-unitFilter__unit.ns-unitFilter__unit--activated {
  display: block;
  transition: all 0.6s;
  opacity: 0;
  transform: scale(0.98);
}
.ns-unitFilter__unit.ns-unitFilter__unit--active {
  opacity: 1;
  transform: scale(1);
}
#cb-cookie-warning {
  position: fixed;
  bottom: 36px;
  z-index: 10000;
  width: 500px;
  max-width: 100%;
  background-color: #fff;
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  line-height: 1.6;
  box-sizing: border-box;
  padding: 30px;
  left: 0;
  transform: translateX(-100%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  left: 0 !important;
  transform: translateX(-100%) !important;
  transition: all 0.3s !important;
}
.show-content #cb-cookie-warning {
  left: 36px;
  transform: translateX(0);
  transition: all 0.6s 0.2s ease;
}
@media (max-width: 767px) {
  .show-content #cb-cookie-warning {
    left: 0;
  }
}
@media (max-width: 767px) {
  #cb-cookie-warning {
    bottom: 0;
    padding: 24px;
    border-radius: 0;
  }
}
.cb-cookie-warning--container {
  float: left;
  width: 100%;
}
.cb-cookie-warning--text {
  float: left;
  width: 100%;
}
.cb-cookie-warning--text a {
  text-decoration: underline;
}
.cb-cookie-warning--text a:hover {
  color: #204e7b;
}
.cb-cookie-warning--actions {
  float: left;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}
#cb-cookie-warning__button--accept {
  float: left;
  order: 1;
  background-color: #204e7b;
  line-height: 1.5;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}
#cb-cookie-warning__button--accept:hover,
#cb-cookie-warning__button--accept:focus {
  color: #fff;
  background-color: #1b4167;
}
#cb-cookie-warning__button--accept:active {
  background-color: #10283e;
}
#cb-cookie-warning__button--decline {
  float: left;
  order: 2;
  background-color: #204e7b;
  line-height: 1.5;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}
#cb-cookie-warning__button--decline:hover,
#cb-cookie-warning__button--decline:focus {
  color: #fff;
  background-color: #1b4167;
}
#cb-cookie-warning__button--decline:active {
  background-color: #10283e;
}
/*# sourceMappingURL=./screen-small.css.map */